import { Grid, IconButton } from '@mui/material';
import React, { FC } from 'react';
import { Legend } from '../../app/api';
import './LegendRatioComponent.css';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

interface Props {
  legendRatio: LegendRatio;
  editClicked: (legendRatio: LegendRatio) => void;
}

const LegendRatioComponent: FC<Props> = ({legendRatio,editClicked}:Props) => {

  // const handList =   ["AA","KK","QQ","JJ","TT","99","88","AKs","AQs","AKo","77","AJs","ATs","AQo","KQs","AJo","KJs","A9s","66","KTs","ATo","A8s","KQo","A7s","QJs","KJo","K9s","A9o","55","QTs","A6s","A5s","A8o","A4s","KTo","K8s","Q9s","QJo","A7o","A3s","JTs","K7s","A2s","44","K9o","QTo","K6s","A5o","A6o","Q8s","K5s","J9s","A4o","K8o","Q9o","K4s","A3o","JTo","Q7s","33","K7o","J8s","T9s","A2o","K3s","Q6s","K2s","K6o","Q8o","Q5s","J9o","K5o","J7s","T8s","Q4s","K4o","Q3s","22","98s","T9o","Q7o","T7s","J6s","J8o","K3o","Q2s","Q6o","J5s","K2o","Q5o","T8o","J7o","J4s","97s","T6s","J3s","Q4o","87s","J2s","T5s","96s","98o","J6o","Q3o","T7o","T4s","Q2o","J5o","86s","T3s","95s","97o","T6o","T2s","J4o","76s","85s","J3o","94s","87o","75s","J2o","T5o","93s","96o","92s","84s","65s","T4o","86o","T3o","74s","95o","83s","64s","76o","54s","T2o","82s","73s","85o","94o","53s","63s","75o","93o","72s","65o","84o","43s","92o","62s","52s","74o","42s","83o","54o","64o","32s","82o","73o","53o","63o","43o","72o","52o","62o","42o","32o"];
  // const handListVs2= ["AA","KK","QQ","JJ","TT","99","AKs","AQs","88","AJs","AKo","KQs","ATs","AQo","77","KJs","A9s","KTs","AJo","KQo","QJs","A8s","66","QTs","ATo","K9s","A7s","KJo","JTs","A5s","A6s","KTo","Q9s","55","A9o","QJo","A4s","K8s","A3s","A8o","K7s","J9s","QTo","A2s","Q8s","K9o","K6s","A7o","T9s","44","JTo","K5s","J8s","A5o","Q7s","A6o","Q9o","K4s","T8s","K3s","K8o","A4o","Q6s","98s","K7o","J7s","K2s","A3o","33","J9o","Q5s","T7s","Q8o","T9o","A2o","K6o","Q4s","J6s","97s","Q3s","J5s","K5o","87s","J8o","22","T6s","Q2s","Q7o","J4s","96s","K4o","T8o","86s","76s","J3s","98o","Q6o","J7o","K3o","T5s","T4s","K2o","J2s","95s","Q5o","T3s","T7o","85s","65s","Q4o","75s","J6o","T2s","97o","94s","87o","Q3o","93s","54s","84s","J5o","64s","T6o","74s","Q2o","92s","J4o","53s","96o","86o","76o","73s","83s","63s","J3o","T5o","82s","43s","J2o","T4o","95o","52s","62s","72s","85o","65o","75o","T3o","42s","32s","T2o","94o","54o","84o","64o","93o","74o","92o","53o","83o","63o","73o","43o","82o","52o","62o","72o","42o","32o"];
  // const handListVs3= ["AA","KK","QQ","JJ","TT","AKs","99","AQs","AJs","KQs","AKo","88","ATs","KJs","AQo","KTs","QJs","77","A9s","AJo","KQo","QTs","A8s","K9s","JTs","A7s","ATo","66","KJo","Q9s","A6s","A5s","QJo","KTo","K8s","A4s","J9s","A9o","T9s","K7s","A3s","55","Q8s","QTo","A2s","K6s","JTo","J8s","A8o","K9o","K5s","T8s","Q7s","K4s","44","98s","A7o","Q6s","J7s","K3s","Q9o","K2s","T7s","Q5s","A5o","A6o","J9o","K8o","97s","87s","33","Q4s","T9o","J6s","A4o","K7o","A3o","Q8o","Q3s","Q2s","J5s","T6s","22","96s","A2o","76s","86s","J4s","J8o","K6o","T8o","J3s","K5o","J2s","T5s","Q7o","75s","98o","T4s","65s","85s","95s","K4o","J7o","T3s","T2s","Q6o","T7o","94s","K3o","74s","64s","54s","Q5o","84s","97o","K2o","93s","87o","Q4o","92s","53s","J6o","73s","83s","63s","43s","Q3o","T6o","J5o","82s","86o","76o","Q2o","96o","52s","62s","J4o","42s","72s","T5o","32s","J3o","75o","65o","95o","85o","J2o","T4o","T3o","54o","64o","T2o","94o","84o","74o","93o","53o","92o","63o","73o","43o","83o","82o","52o","62o","42o","72o","32o"];
  // const cards = ["2", "3", "4", "5", "6", "7", "8", "9", "T", "J", "Q", "K", "A"];

  /*

  const isSelected = (hand) => {
    return !!legendRatio.hands.find(r => r === hand);
  }

// return 6
  const isPair = (hand) => {
    return hand.charAt(0) === hand.charAt(1);
  };
// si pas pair, 4 si suited, 12 sinon
  const isSuited = (hand) => {
    return hand.length === 3 && hand.charAt(2) === 's';
  };

  const getPercent = () => {
    var totalChecked = 0;
    for (let key in handList) {
        if (isSelected(handList[key])) {
            if (isPair(handList[key])) {
                totalChecked += 6;
            } else {
                if (isSuited(handList[key])) {
                    totalChecked += 4;
                } else {
                    totalChecked += 12;
                }
            }
        }
    }
    return totalChecked / 1326;
  }
  */

  /*
  const getHandsFromPercent = (percent) => {
    var a = percent * 1326 / 100;
    var b = 0;
    for (let key in handList) {
        if (isPair(handList[key])) {
            b = b + 6
        } else {
            if (isSuited(handList[key])) {
                b = b + 4
            } else {
                b = b + 12
            }
        }
        if (b > a) {
            console.log('false ' + handList[key]);
            // setSelected(handList[key], false)
        } else {
            console.log('true ' + handList[key]);
            // setSelected(handList[key], true)
        }
    }
  }

  const updateRange = (inputHands:string) => {
    console.log(inputHands);
    var handsSplitted = inputHands.split(',');
    console.log(handsSplitted); 
    var allHands:string[] = [];
    for(var i = 0; i < handsSplitted.length; i++){
      allHands = allHands.concat(getAllHands(handsSplitted[i]));
    }
    var uniqueHands = uniqueArray(allHands);
    console.log(uniqueHands); 
    console.log(uniqueHands.length + ' hands'); 
  };

  const uniqueArray = (array:string[]) => {
    return [...new Set(array)];
  }

  const getAllHands = (singleHand:string) :string[] => {
    if(!singleHand.endsWith('+')){
      return [singleHand];
    }
    else{
      return [...getAllHands(getNextHand(singleHand)), singleHand.substring(0,singleHand.length-1)];
    }
  }

  const getNextHand = (hand:string) :string => {
    switch (hand) {
      case '22+': return '33+';
      case '33+': return '44+';
      case '44+': return '55+';
      case '55+': return '66+';
      case '66+': return '77+';
      case '77+': return '88+';
      case '88+': return '99+';
      case '99+': return 'TT+';
      case 'TT+': return 'JJ+';
      case 'JJ+': return 'QQ+';
      case 'QQ+': return 'KK+';
      case 'KK+': return 'AA';

      case '32s+': return '43s+';
      case '43s+': return '54s+';
      case '54s+': return '65s+';
      case '65s+': return '76s+';
      case '76s+': return '87s+';
      case '87s+': return '98s+';
      case '98s+': return 'T9s+';
      case 'T9s+': return 'JTs+';
      case 'JTs+': return 'QJs+';
      case 'QJs+': return 'KQs+';
      case 'KQs+': return 'AKs';

      case '32o+': return '43o+';
      case '43o+': return '54o+';
      case '54o+': return '65o+';
      case '65o+': return '76o+';
      case '76o+': return '87o+';
      case '87o+': return '98o+';
      case '98o+': return 'T9o+';
      case 'T9o+': return 'JTo+';
      case 'JTo+': return 'QJo+';
      case 'QJo+': return 'KQo+';
      case 'KQo+': return 'AKo';

      case 'A2o+': return 'A3o+';
      case 'A3o+': return 'A4o+';
      case 'A4o+': return 'A5o+';
      case 'A5o+': return 'A6o+';
      case 'A6o+': return 'A7o+';
      case 'A7o+': return 'A8o+';
      case 'A8o+': return 'A9o+';
      case 'A9o+': return 'ATo+';
      case 'ATo+': return 'AJo+';
      case 'AJo+': return 'AQo+';
      case 'AQo+': return 'AKo+';
      case 'AKo+': return 'AA';

      case 'A2s+': return 'A3s+';
      case 'A3s+': return 'A4s+';
      case 'A4s+': return 'A5s+';
      case 'A5s+': return 'A6s+';
      case 'A6s+': return 'A7s+';
      case 'A7s+': return 'A8s+';
      case 'A8s+': return 'A9s+';
      case 'A9s+': return 'ATs+';
      case 'ATs+': return 'AJs+';
      case 'AJs+': return 'AQs+';
      case 'AQs+': return 'AKs+';
      case 'AKs+': return 'AA';

      default: return 'AA';
    }
  }
  */

  return (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        {legendRatio.legend.title}
      </Grid> 
      <Grid item xs={2}>
        <span className='legendRatioWeight'>({ (legendRatio.weight / 1326 * 100).toFixed(2)}%)</span>
      </Grid>
      <Grid item xs={6}>
        <div className='legendRatioWeightBars'>
          <div style={{backgroundColor: legendRatio.legend.color, width:((legendRatio.weight / 1326 * 100)).toFixed(2) + '%'}}>&nbsp;</div>
        </div>
      </Grid>
      <Grid item xs={1}>
        {
          legendRatio.legend.id && 
          <IconButton color='info' aria-label="Edit" onClick={() => editClicked(legendRatio)}>
              <EditRoundedIcon />
          </IconButton>
        }
      </Grid>
    </Grid>
  );
}

class LegendRatio {
  legend: Legend; 
  weight: number;
  hands: string[] = [];
}

export default LegendRatioComponent;
