import './HandProportionsFormDialog.css';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { Legend } from '../../app/api';

interface Props {
  legends: Legend[];
  open: boolean;
  hands: string[];
  legend: Legend;  
  onSave: (handsText, weight) => void;
  onCancel: () => void;
}

const HandProportionsBulkFormDialog: FC<Props> = ({ legends, open, hands, legend, onSave, onCancel }: Props) => {
    
  const [isFormValid] = useState(true);
  const [rangeText, setRangeText] = useState<string>("");
  const [weight] = useState<number>(100);

  useEffect(() => {
    const getRangeLink = () => {
      let g = "";
      let d = -1;
      let f = 13;
      for (let e = cards.length - 1; e >= 0; e--) {
          let b = cards[e] + cards[e];
          if (isSelected(b)) {
              d = e;
              if (f >= 13) {
                  f = e
              }
          }
          if (!isSelected(b) || (d === 0)) {
              if (d >= 0) {
                  if (d === f) {
                      g = addCards(g, cards[d] + cards[d])
                  } else {
                      if (f >= 12) {
                          g = addCards(g, cards[d] + cards[d] + "+")
                      } else {
                          g = addCards(g, cards[d] + cards[d] + "-" + cards[f] + cards[f])
                      }
                  }
              }
              d = -1;
              f = 13
          }
      }
      let a = ["s", "o"];
      for (let key in a) {
          for (let e = cards.length - 1; e >= 0; e--) {
              d = -1;
              f = e;
              for (let c = e - 1; c >= 0; c--) {
                  let b = cards[e] + cards[c] + a[key];
                  if (isSelected(b)) {
                      d = c;
                      if (f >= e) {
                          f = c
                      }
                  }
                  if (!isSelected(b) || (d === 0)) {
                      if (d >= 0) {
                          if (d === f) {
                              g = addCards(g, cards[e] + cards[d] + a[key])
                          } else {
                              if (f >= e - 1) {
                                  g = addCards(g, cards[e] + cards[d] + a[key] + "+")
                              } else {
                                  g = addCards(g, cards[e] + cards[d] + a[key] + "-" + cards[e] + cards[f] + a[key])
                              }
                          }
                      }
                      d = -1;
                      f = e
                  }
              }
          }
      }
      return g;
    };

    const cards = ["2", "3", "4", "5", "6", "7", "8", "9", "T", "J", "Q", "K", "A"];

    const isSelected = (hand) => {
      return !!hands.find(r => r === hand);
    };

    setRangeText(getRangeLink());
  }, [legends, hands]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRangeText(event.target.value);
  }

  const addCards = (link, cardToAdd) => {
    let existingLink = link;
    if(existingLink !== ''){
      existingLink = existingLink + ",";
    }
    existingLink = existingLink + cardToAdd;
    return existingLink;
  };

  return (
    <Dialog open={open} fullWidth={true}>
      <DialogTitle>Edit actions</DialogTitle>
      <DialogContent>
        <div>
          {legend.title} {legend.color} {weight}
          <TextField
            autoFocus
            margin="dense"
            label="Title"
            type="text"
            name="title"
            value={rangeText}
            onChange={handleInputChange}
            fullWidth
          />
        </div>
      </DialogContent>
      <DialogActions>
      <Button onClick={onCancel} color="primary">
        Cancel
      </Button>
      <Button onClick={() => onSave(rangeText, weight)} disabled={!isFormValid} variant='contained' color='success'>
        Save
      </Button>
      </DialogActions>
    </Dialog>
  );
}

export default HandProportionsBulkFormDialog;