import './App.css';
import React from 'react';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import store from './redux/store'
import AppContainerComponent from './components/AppContainerComponent/AppContainerComponent';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <AppContainerComponent />
      </ThemeProvider>
    </Provider>
  );
}

export default App;