import axios from "axios";
import { DataClient, SignInRequest, SignInResponse, SignUpRequest, SignUpResponse, UserClient } from "../app/api";
import { setIsLoading, setLegends, setPokerKinds, setToken, setTrainerTests } from "../redux/slices/dataSlice";
import store from "../redux/store";

export abstract class UserService {
    public static signIn = (usernameOrEmail:string, password): Promise<SignInResponse> => {
        store.dispatch(setIsLoading(true));
        let client = new UserClient(process.env.REACT_APP_API_BASE_URL);
        return client.signIn({
          usernameOrEmail: usernameOrEmail,
          password: password
        } as SignInRequest);
    };

    public static signUp = (email: string, username: string, password: string, confirmPassword: string): Promise<SignUpResponse> => {
        store.dispatch(setIsLoading(true));
        let client = new UserClient(process.env.REACT_APP_API_BASE_URL, axios);
        return client.signUp({
            email: email,
            username: username,
            password: password,
            confirmPassword: confirmPassword, 
          } as SignUpRequest);
    }

    public static logOut = (): void => {
        store.dispatch(setToken(null));
    }

    public static getData = (): void => {
        let data = new DataClient(process.env.REACT_APP_API_BASE_URL, axios);
        data.getData().then((data) => {
          store.dispatch(setLegends(data.legends));
          store.dispatch(setPokerKinds(data.pokerKinds));
          store.dispatch(setTrainerTests(data.trainerTests));
        });
    }
};