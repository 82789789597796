import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { object, string, ZodError } from 'zod';
import { StackSize } from '../../app/api';

const stacksizeSchema = object({
  title: string()
    .min(1, 'Title is required'),
});
  
interface Props {
  item: StackSize;
  title: string;
  open: boolean;
  onSave: (stacksize: StackSize) => void;
  onCancel: () => void;
}

const StackSizeFormDialog: FC<Props> = ({ item, title, open, onSave, onCancel }: Props) => {
    const [currentItem, setCurrentItem] = useState<StackSize>(item);
    const [isFormValid, setIsFormValid] = useState(false);
    const [errors, setErrors] = useState({ title: '', color: '' });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setCurrentItem({ ...currentItem, [name]: value } as StackSize);
      validateForm();
    }

    useEffect(() => {
      setCurrentItem(item);
      setErrors({ title: '', color: '' });
    }, [item]);

    const validateForm = () => {
      let formErrors = { title: '', color: '' };
      let formIsValid = true;
  
      try {
        stacksizeSchema.parse({ ...currentItem });
      } catch (err) {
        const error = err as ZodError;
        error.errors.forEach((e) => {
            formErrors[e.path[0]] = e.message;
        });
        formIsValid = false;
      }
  
      setErrors(formErrors);
      setIsFormValid(formIsValid);
    }

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Title"
          type="text"
          name="title"
          value={currentItem.title}
          onChange={handleInputChange}
          error={!!errors.title}
          helperText={errors.title}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
      <Button onClick={onCancel} color="primary">
        Cancel
      </Button>
      <Button onClick={() => onSave(currentItem)} disabled={!isFormValid} variant='contained' color='success'>
        Save
      </Button>
      </DialogActions>
    </Dialog>
  );
}

export default StackSizeFormDialog;