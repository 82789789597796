import React, { useEffect, useState } from 'react';
import { Button, Box, Tabs, Tab, Grid } from '@mui/material';
import AxiosJwtTokenInterceptor from '../../interceptors/AxiosJwtTokenInterceptor';
import { UserService } from '../../services/UserService';
import AccountComponent from '../AccountComponent/AccountComponent';
import LegendsList from '../LegendItem/LegendsList';
import TrainerComponent from '../TrainerComponent/TrainerComponent';
import { useAppSelector } from '../../redux/hooks';
import DashboardComponent from '../DashboardComponent/DashboardComponent';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
}
  
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AppContainerComponent = () => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const token = useAppSelector((state) => state.data.token);
    const isLoading = useAppSelector((state) => state.data.isLoading);

    useEffect(() => {
        if(token) {
            UserService.getData();
        }
    }, [token]);
  
    const handleTabChange = (_event: React.SyntheticEvent, newTabIndex: number) => {
      setSelectedTabIndex(newTabIndex);
    };
  
    return (
        <div className='appContainer'>
          <AxiosJwtTokenInterceptor />
          {
            isLoading ? 
              <div>
                Loading... Please wait
                <Button variant='contained' onClick={() => UserService.logOut()}>Logout</Button>
              </div>
            : 
              token ? 
                <div>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTabIndex} onChange={handleTabChange} aria-label="Tabs">
                      <Tab label="Dashboard" {...a11yProps(0)} />
                      <Tab label="Actions" {...a11yProps(1)} />
                      <Tab label="Trainer" {...a11yProps(2)} />
                      <Tab label="Account" {...a11yProps(2)} />
                    </Tabs>
                  </Box>
                  <TabPanel value={selectedTabIndex} index={0}>
                    <DashboardComponent />
                  </TabPanel>
                  <TabPanel value={selectedTabIndex} index={1}>
                    <LegendsList />
                  </TabPanel>
                  <TabPanel value={selectedTabIndex} index={2}>
                    <TrainerComponent />
                  </TabPanel>
                  <TabPanel value={selectedTabIndex} index={3}>
                    <Button variant='contained' onClick={() => UserService.logOut()}>Logout</Button>
                  </TabPanel>
                </div>
              :
                <Grid container alignItems='center' justifyContent='center'>
                  <Grid item xs={12} sm={10} md={8} lg={6} xl={3} >
                    <AccountComponent />
                  </Grid>
                </Grid>
            }
        </div>
    );
}

export default AppContainerComponent;
