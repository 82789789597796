import axios from 'axios';
import { useEffect, useState } from 'react';

/**
 * @function AxiosJwtTokenInterceptor
 * @description Interceptors that injects automatically the jwt token and refresh the token if necessary
 */
const AxiosJwtTokenInterceptor = (props) => {
    const [alreadyAdded, setAlreadyAdded] = useState(false); 

    useEffect(() => {
        if (alreadyAdded)
            return; 

        setAlreadyAdded(true);

        axios.interceptors.request.use(
            async config => {
                config.headers = { 
                    'Authorization': 'Bearer ' + window.localStorage.getItem('accessToken'),
                    "Content-Type": "application/json"
                };
                return config;
            },
            error => {
                Promise.reject(error);
            },
        );
    
        axios.interceptors.response.use((response) => {
            return response;
        }, async function (error) {
            const originalRequest = error.config;
            if (error.response.status === 401 && !originalRequest._retry) {
                const refreshToken = window.localStorage.getItem('refreshToken');
                const email = window.localStorage.getItem('email');

                if (email && refreshToken) {
                    originalRequest._retry = true;
                    /*
                    await refreshAdminToken( { email: email, refreshToken: refreshToken } ).then((newToken) => {
                        window.localStorage.setItem('accessToken', newToken);
                        axios.defaults.headers.common.Authorization = 'Bearer ' + newToken;
                    });
                    */
                    return axios(originalRequest);
                } else {
                    window.localStorage.removeItem('accessToken');
                    window.location.reload();
                    // alert('Vous n\'êtes pas connecté.');
                }
            } else if (error.response.status === 400 && 
                (error.response.data.message === 'exceptions.refresh-token-not-found' || error.response.data.message === 'exceptions.invalid-refresh-token') ) {
                alert('Vous devez vous reconnecter.');
            }
            console.log('Une erreur est survenue lors de l\'appel du ws : ');
            return Promise.reject(error);
        });
    }, [alreadyAdded]);

    return null;
};

export default AxiosJwtTokenInterceptor;