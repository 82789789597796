import { Button } from '@mui/material';
import React, { useState } from 'react';
import SignInComponent from '../SignInComponent/SignInComponent';
import SignUpComponent from '../SignUpComponent/SignUpComponent';

function AccountComponent(props) {
    const [signIn, setSignIn] = useState<boolean>(true);

    return (
        <div>
            <img src="logo.png" alt="All-in Range" />
            { signIn && 
                <div>
                    <SignInComponent />
                    <br/>
                    <div>
                        Not a member ? <Button variant='outlined' onClick={() => setSignIn(false)}>Sign up now</Button>
                    </div>
                </div>
            }
            { !signIn && 
                <div>
                        <SignUpComponent />
                        <br/>
                        <div>
                        Already a member ? <Button variant='outlined' onClick={() => setSignIn(true)}>Sign in now</Button>
                    </div>
                </div>
            }
        </div>
    );
}

export default AccountComponent;