import axios from "axios";
import { CreatePokerKindRequest, PokerKind, PokerKindClient, UpdatePokerKindRequest } from "../app/api";
import { pokerKindAdded, pokerKindDeleted, pokerKindUpdated } from "../redux/slices/dataSlice";
import store from "../redux/store";

export abstract class PokerKindService {
    public static handlePokerKindAdded = (pokerkind: PokerKind): void => {
        let client = new PokerKindClient(process.env.REACT_APP_API_BASE_URL, axios);
        let request = new CreatePokerKindRequest();
        request.title = pokerkind.title; 
        client.create(request).then((pokerKindCreated) => {
            store.dispatch(pokerKindAdded(new PokerKind(pokerKindCreated)))
        });
    };

    public static handlePokerKindUpdated(pokerkind: PokerKind): void {
        let client = new PokerKindClient(process.env.REACT_APP_API_BASE_URL, axios);
        let request = new UpdatePokerKindRequest();
        request.id = pokerkind.id;
        request.title = pokerkind.title;
        if(pokerkind.id){
            client.update(pokerkind.id, request).then((item) => {
                store.dispatch(pokerKindUpdated(new PokerKind(item)))
            });
        }
    }
    
    public static handlePokerKindDeleted(pokerkind: PokerKind): void {
        let client = new PokerKindClient(process.env.REACT_APP_API_BASE_URL, axios);
        if(pokerkind.id){
            client.delete(pokerkind.id).then(() => {
                store.dispatch(pokerKindDeleted(pokerkind))
            });
        }
    }
};