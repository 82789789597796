import './HandProportionsFormDialog.css';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, MenuItem, Select, TextField } from '@mui/material';
import Slider from '@mui/material/Slider';
import React, { FC, useEffect, useState } from 'react';
import { Proportion } from '../../app/api';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { useAppSelector } from '../../redux/hooks';

interface Props {
  open: boolean;
  proportions: Proportion[];
  onSave: (proportions: Proportion[]) => void;
  onCancel: () => void;
}

const HandProportionsFormDialog: FC<Props> = ({ proportions, open, onSave, onCancel }: Props) => {
    
    const [isFormValid, setIsFormValid] = useState(false);
    const [errors, setErrors] = useState({ sum: '' });
    const [currentProportions, setCurrentProportions] = useState(proportions);
    const legends = useAppSelector((state) => state.data.legends);

    useEffect(() => {
      setCurrentProportions(proportions);
      setErrors({ sum: '' });
      validateForm(proportions);
    }, [proportions])

    const handleChange = (newValue: number | number[], index: number) => {
      let newProportions = currentProportions.map((p,i) => i === index ? {legendId:p.legendId, rate:newValue} as Proportion : p );
      setCurrentProportions(newProportions); 
      validateForm(newProportions);
    }

    const onAddClick = () => {
      let newProportions = [...currentProportions, { legendId: legends[0].id, rate: 100 - currentProportions.map(p => p.rate ?? 0).reduce((a,b) => a + b, 0)} as Proportion];
      setCurrentProportions(newProportions);
      validateForm(newProportions);
    }

    const handleDeleteProportionClick = (index: number) => {
      let newProportions = currentProportions.filter((_, i) => i !== index);
      setCurrentProportions(newProportions); 
      validateForm(newProportions);
    }

    const handleSelectChanged = (e, index) => {
      let newProportions = currentProportions.map((p,i) => i === index ? {legendId:e.target.value, rate:p.rate} as Proportion : p );
      setCurrentProportions(newProportions); 
      validateForm(newProportions);
    };

    const validateForm = (newProportions: Proportion[]) => {
      let formErrors = { sum: ''};
      let formIsValid = true;
  
      try {
        var total = newProportions.map(p => p.rate ?? 0).reduce((a,b) => a+b, 0);
        if(total > 100){
          formIsValid = false;
          formErrors.sum = 'Total should be max at 100';
        }
      } catch (err) {
        formIsValid = false;
      }
      setErrors(formErrors);
      setIsFormValid(formIsValid);
    }

    /*
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setCurrentItem({ ...currentItem, [name]: value } as StackSize);
      validateForm();
    }
    */

  return (
    <Dialog open={open} fullWidth={true}>
      <DialogTitle>Edit actions</DialogTitle>
      <DialogContent>
        <div className='editProportionContainer'>
          { currentProportions.map((p, index) => 
            <div className='editProportionItemContainer'>
              <FormControl fullWidth>
                <Select value={p.legendId} onChange={(e) => handleSelectChanged(e, index)}>
                    { legends.map((legend, indexMenuItem) => (
                          <MenuItem key={indexMenuItem} value={legend.id}>
                            <Chip style={{ backgroundColor: legends.find(l => l.id === legend.id)?.color }} /> {legend.title}
                          </MenuItem>
                        ))
                    }
                </Select>
              </FormControl>
              <Slider value={p.rate} step={1} min={0} max={100} onChange={(_, value) => handleChange(value, index)} />
              <TextField type="number" 
                InputProps={{
                    inputProps: { min: 0, max: 100 }
                  }}
                value={p.rate}
                fullWidth={true}
                onChange={(e) => handleChange(+e.target.value, index)} 
                error={!!errors.sum}
                helperText={errors.sum}
              />
              <IconButton color='error' aria-label="Delete" onClick={() => handleDeleteProportionClick(index)}>
                <DeleteOutlineRoundedIcon />
              </IconButton>
            </div>
          )}
        <Button variant='outlined' onClick={onAddClick} color="success">
          Add
        </Button>
        </div>
      </DialogContent>
      <DialogActions>
      <Button onClick={onCancel} color="primary">
        Cancel
      </Button>
      <Button onClick={() => onSave(currentProportions)} disabled={!isFormValid} variant='contained' color='success'>
        Save
      </Button>
      </DialogActions>
    </Dialog>
  );
}

export default HandProportionsFormDialog;