import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import dataReducer from './slices/dataSlice';

const store = configureStore({
   reducer: {
    data: dataReducer,
   },
   middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;