import React, { FC, useEffect, useState } from 'react';
import { Legend, StackSize } from '../../app/api';
import { useAppSelector } from '../../redux/hooks';
import HandProportionsBulkFormDialog from '../FormDialogs/HandProportionsBulkFormDialog';
import LegendRatioComponent from '../LegendRatioComponent/LegendRatioComponent';
import './HandsLegendsComponent.css';

interface Props {
    stackSize: StackSize;
    handleBulkUpdateStackSizeLegend: (rangeText:string, weight:number, legend:Legend) => void;
}

const HandsLegendsComponent:FC<Props> = ({stackSize, handleBulkUpdateStackSizeLegend}:Props) => {
    const [legendRatios, setLegendRatios] = useState<LegendRatio[]>([]);
    const [selectedLegendRatio, setSelectedLegendRatio] = useState<LegendRatio>();
    const [editBulkFormOpen, setEditBulkFormOpen] = useState<boolean>(false);
    const legends = useAppSelector((state) => state.data.legends);

    useEffect(() => {
        const isPair = (hand: String): boolean => {
            return hand.charAt(0) === hand.charAt(1);
        };

        const isSuited = (hand: String): boolean => {
            return hand.length === 3 && hand.charAt(2) === 's';
        };
        
        const getWeight = (hand: String) : number => {
            if (isPair(hand)) {
                return 6;
            } else {
                if (isSuited(hand)) {
                    return 4;
                } else {
                    return 12; 
                }
            }
        }

        const calculateRatios = () => {
            let legendRatiosTmp: LegendRatio[] = [];
            if(stackSize.hands) {
                for (let key in stackSize.hands) {
                    let proportions = stackSize.hands[key];
                    proportions?.forEach(proportion => {
                        // if found in legends params 
                        if(proportion.rate && proportion.legendId && legends?.filter(l => l.id === proportion.legendId).length > 0){
                            // if found in existing legend ratio 
                            if(legendRatiosTmp?.filter(l => l.legend.id === proportion.legendId).length > 0){
                                let legendRatio = legendRatiosTmp?.filter(l => l.legend.id === proportion.legendId)[0];
                                legendRatio.weight += getWeight(key) * proportion.rate / 100;
                                legendRatio.hands = [...legendRatio.hands, key]
                            }
                            else{
                                legendRatiosTmp?.push({
                                    legend : legends?.filter(l => l.id === proportion.legendId)[0], 
                                    weight : getWeight(key) * proportion.rate / 100, 
                                    hands: [key]
                                });
                            }
                        }
                    })
                }
            }
            let total = legendRatiosTmp.map(l => l.weight).reduce((sum, current) => sum + current, 0);
            if(total !== 1326) {
                legendRatiosTmp?.push({
                    legend: {
                        color: "#000000",
                        title: "Not specified",
                        id: "",
                        weight: 0
                    } as Legend,
                    weight: 1326 - total, 
                    hands: []
                });
            }            
            return legendRatiosTmp;
        };

        setLegendRatios(calculateRatios())
      }, [legends, stackSize]);

    const editProportionsBulkClicked = (legendRatio: LegendRatio) => {
        setSelectedLegendRatio(legendRatio);
        setEditBulkFormOpen(true);
    }

    const onBulkSave = (rangeText:string, weight:number) => {
        if(selectedLegendRatio)
            handleBulkUpdateStackSizeLegend(rangeText, weight, selectedLegendRatio.legend);
        setEditBulkFormOpen(false);
    };

    return (
        <div className='legendRatioContainer'>
            {legendRatios?.sort((a, b) => b.weight - a.weight).map((item,index) => (
                <LegendRatioComponent key={index} legendRatio={item} editClicked={editProportionsBulkClicked} />
            ))}
            {
                selectedLegendRatio &&
                <HandProportionsBulkFormDialog open={editBulkFormOpen} legends={legends} hands={selectedLegendRatio.hands} legend={selectedLegendRatio.legend} onSave={onBulkSave} onCancel={() => setEditBulkFormOpen(false)} />
            }
        </div>
    );
}

class LegendRatio {
    legend: Legend; 
    weight: number;
    hands: string[] = [];
}

export default HandsLegendsComponent;