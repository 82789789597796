import axios from "axios";
import { CreateLegendRequest, Legend, LegendClient, UpdateLegendRequest } from "../app/api";
import { legendAdded, legendDeleted, legendUpdated } from "../redux/slices/dataSlice";
import store from "../redux/store";

export abstract class LegendService {
    public static handleLegendAdded = (legend: Legend): void => {
        let client = new LegendClient(process.env.REACT_APP_API_BASE_URL, axios);
        let request = new CreateLegendRequest();
        request.title = legend.title; 
        request.color = legend.color; 
        client.create(request).then((addedLegend) => {
            if(addedLegend){
                store.dispatch(legendAdded(new Legend(addedLegend)))
            }
        });
    };

    public static handleLegendUpdated(legend: Legend): void {
        let client = new LegendClient(process.env.REACT_APP_API_BASE_URL, axios);
        let request = new UpdateLegendRequest();
        request.id = legend.id;
        request.title = legend.title; 
        request.color = legend.color;
        if(legend.id){
            client.update(legend.id, request).then((updatedLegend) => {
                if(updatedLegend){
                    store.dispatch(legendUpdated(updatedLegend))
                }
            });
        }
    }
    
    public static handleLegendDeleted(legend: Legend): void {
        let client = new LegendClient(process.env.REACT_APP_API_BASE_URL, axios);
        if(legend.id){
            client.delete(legend.id).then(() => {
                store.dispatch(legendDeleted(legend))
            });
        }
    }
};