import axios from "axios";
import { CreateTrainerTestRequest, TrainerTest, TrainerTestClient } from "../app/api";
import { trainerTestAdded } from "../redux/slices/dataSlice";
import store from "../redux/store";

export abstract class TrainerTestService {
    public static handleTrainerTestAdded = (trainerTest: TrainerTest): void => {
        let client = new TrainerTestClient(process.env.REACT_APP_API_BASE_URL, axios);
        let request = new CreateTrainerTestRequest();
        request.init(trainerTest);
        client.create(request).then((addedTrainerTest) => {
            if(addedTrainerTest){
                store.dispatch(trainerTestAdded(new TrainerTest(addedTrainerTest)));
            }
        });
    };
};