import { Button, ButtonGroup, Grid, IconButton } from '@mui/material';
import React, { FC } from 'react';
import { Position, StackSize } from '../../app/api';
import { setSelectedPosition, setSelectedStackSize } from '../../redux/slices/dataSlice';
import { useAppSelector } from '../../redux/hooks';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import store from '../../redux/store';

interface Props {
    position: Position;
    handlePositionContextMenuClick: (event: React.MouseEvent<HTMLElement>, position: Position) => void,
    handleStackSizeContextMenuClick: (event: React.MouseEvent<HTMLElement>, stackSize: StackSize) => void, 
  }

const PositionComponent: FC<Props> = ({ position, handlePositionContextMenuClick, handleStackSizeContextMenuClick }: Props) =>{

    const selectedStackSize = useAppSelector((state) => state.data.selectedStackSize);
    const stackSizes = useAppSelector((state) => state.data.stackSizes);

    return (
        <fieldset key={position.id} className='border border-solid border-gray-300 p-3'>
            <legend className='text-sm'>
                {position.title}
                <IconButton onClick={(event) => handlePositionContextMenuClick(event, position)}>
                    <MoreVertIcon />
                </IconButton>
            </legend>
            <div className='stackSizeContainer'>
                {
                    stackSizes && position.id &&
                    <ButtonGroup
                        orientation="vertical"
                        aria-label="vertical outlined button group">
                        {stackSizes[position.id]?.map(stackSize => (
                            <Button color={selectedStackSize && stackSize.id === selectedStackSize.id ? 'success' : 'primary'} variant="outlined" key={stackSize.id} onClick={() => { store.dispatch(setSelectedStackSize(stackSize)); store.dispatch(setSelectedPosition(position));}}>
                                <Grid container>
                                    <Grid item xs={11}>
                                        {stackSize.title}
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={(event) => handleStackSizeContextMenuClick(event, stackSize)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Button>
                        ))}
                    </ButtonGroup>
                }
            </div>
        </fieldset>
    );
}

export default PositionComponent;