import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Box, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { UserService } from '../../services/UserService';
import { useAppSelector } from '../../redux/hooks';
import { setIsLoading, setToken } from '../../redux/slices/dataSlice';
import store from '../../redux/store';

const registerSchema = object({
  username: string()
    .min(1, 'Username is required')
    .max(32, 'Username must be less than 32 characters'),
  email: string()
    .min(1,'Email is required')
    .email('Email is invalid'),
  password: string()
    .min(1, 'Password is required')
    .min(6, 'Password must be more than 6 characters')
    .max(32, 'Password must be less than 32 characters'),
  confirmPassword: string()
    .min(1,'Please confirm your password'),
}).refine((data) => data.password === data.confirmPassword, {
  path: ['confirmPassword'],
  message: 'Passwords do not match',
});

type RegisterInput = TypeOf<typeof registerSchema>;

function SignUpComponent() {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const isLoading = useAppSelector((state) => state.data.isLoading);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema)
  });
  
  const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {
    UserService.signUp(values.email, values.username, values.password, values.confirmPassword)
      .then((result) => {
        if(result.token){
          store.dispatch(setToken(result.token));
          UserService.getData();
        }
      })
      .catch((result) => {
        if(result.Email){
          setError('email', { type: 'custom', message: result.Email[0]})
        }
        if(result.ConfirmPassword){
          setError('confirmPassword', { type: 'custom', message: result.ConfirmPassword[0]})
        }
      })
      .finally(() => {
        store.dispatch(setIsLoading(false));
      });
  };

  return (
    <Box sx={{ maxWidth: '30rem' }}>
      <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
        Sign up
      </Typography>
      <Box
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmitHandler)}
      >
      <TextField
        sx={{ mb: 2 }}
        label='Username'
        fullWidth
        required
        error={!!errors['username']}
        helperText={errors['username'] ? errors['username'].message : ''}
        {...register('username')}
      />
      <TextField
        sx={{ mb: 2 }}
        label='Email'
        fullWidth
        required
        type='email'
        error={!!errors['email']}
        helperText={errors['email'] ? errors['email'].message : ''}
        {...register('email')}
      />
      <TextField
        sx={{ mb: 2 }}
        label='Password'
        fullWidth
        required
        type={showPassword ? 'text' : 'password'}
        error={!!errors['password']}
        helperText={errors['password'] ? errors['password'].message : ''}
        {...register('password')}
        InputProps={{
          endAdornment: <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }}
      />
      <TextField
        sx={{ mb: 2 }}
        label='Confirm Password'
        fullWidth
        required
        type='password'
        error={!!errors['confirmPassword']}
        helperText={
          errors['confirmPassword'] ? errors['confirmPassword'].message : ''
        }
        {...register('confirmPassword')}
        InputProps={{
          endAdornment: <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }}
      />

      <LoadingButton
        variant='contained'
        fullWidth
        type='submit'
        loading={isLoading}
        sx={{ py: '0.8rem', mt: '1rem' }}
      >
        Register
      </LoadingButton>
    </Box>
  </Box>
  );
}

export default SignUpComponent;