import axios from "axios";
import { CreatePositionRequest, Position, PositionClient, UpdatePositionRequest } from "../app/api";
import { positionAdded, positionDeleted, positionUpdated } from "../redux/slices/dataSlice";
import store from "../redux/store";

export abstract class PositionService {
    public static handlePositionAdded = (position: Position): void => {
        let client = new PositionClient(process.env.REACT_APP_API_BASE_URL, axios);
        let request = new CreatePositionRequest();
        request.title = position.title; 
        request.pokerKindId = position.pokerKindId;
        client.create(request).then((positionCreated) => {
            store.dispatch(positionAdded(new Position(positionCreated)))
        });
    };

    public static handlePositionUpdated(position: Position): void {
        let client = new PositionClient(process.env.REACT_APP_API_BASE_URL, axios);
        let request = new UpdatePositionRequest();
        request.id = position.id;
        request.title = position.title;
        request.pokerKindId = position.pokerKindId;
        if(position.id){
            client.update(position.id, request).then((item) => {
                store.dispatch(positionUpdated(new Position(item)))
            });
        }
    }
    
    public static handlePositionDeleted(position: Position): void {
        let client = new PositionClient(process.env.REACT_APP_API_BASE_URL, axios);
        if(position.id){
            client.delete(position.id).then(() => {
                store.dispatch(positionDeleted(position))
            });
        }
    }
};