import React, { useState } from 'react';
import { Legend } from '../../app/api';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import LegendFormDialog from './LegendFormDialog';
import { useAppSelector } from '../../redux/hooks';
import { LegendService } from '../../services/LegendService';

const LegendsList = () => {
  const [selectedItem, setSelectedItem] = useState<Legend>({ id: undefined, title: '' } as Legend);

  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [editAlertOpen, setEditAlertOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const legends = useAppSelector((state) => state.data.legends);

  const handleDeleteClick = (item: Legend) => {
    setSelectedItem(item);
    setDeleteAlertOpen(true);
  }

  const handleAddClick = () => {
    console.log(legends);
    setSelectedItem({ id: undefined, title: '', color: '#0051FF' } as Legend);
    setDialogTitle('Add legend')
    setEditAlertOpen(true);
  }

  const handleEditClick = (item: Legend) => {
    setSelectedItem(item);
    setDialogTitle('Edit legend')
    setEditAlertOpen(true);
  }

  const handleClose = () => {
    setEditAlertOpen(false);
    setSelectedItem({ title: '' } as Legend);
  };

  const handleSave = (item:Legend) => {
    setEditAlertOpen(false);
    if (item.id) {
      LegendService.handleLegendUpdated(item);
    } else {
      LegendService.handleLegendAdded(item);
    }
  }

  const columns: GridColDef[] = [
    { field: 'color', headerName: 'Color', maxWidth: 50, renderCell: function render({ row }) {
      return (
        <Chip style={{ backgroundColor: row.color }} />
      );
    },
     },
    { field: 'title', headerName: 'Title', flex: 1 },
    { field: 'actions', headerName: 'Actions', align: 'center', flex: 1, renderCell: function render({ row }) {
      return (
        <div key={row.id}>
          <IconButton color='info' aria-label="Edit" onClick={() => handleEditClick(row)}>
            <EditRoundedIcon />
          </IconButton>
          <IconButton color='error' aria-label="Delete" onClick={() => {handleDeleteClick(row);}}>
            <DeleteOutlineRoundedIcon />
          </IconButton>
        </div>
        );
    } }
  ];

  const deleteItem = () => {
    setDeleteAlertOpen(false);
    LegendService.handleLegendDeleted(selectedItem);
  }

  return (
    <div>
      <DataGrid rows={legends} columns={columns} rowHeight={25} autoHeight />
      <Button variant='contained' color='success' onClick={handleAddClick}>Add</Button>
      <Dialog
        open={deleteAlertOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {"Delete legend"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {setDeleteAlertOpen(false)}} variant='outlined'>Cancel</Button>
          <Button onClick={deleteItem} variant='contained' color='error' autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <LegendFormDialog item={selectedItem} open={editAlertOpen} title={dialogTitle} onSave={handleSave} onCancel={handleClose} />
    </div>
  );
}

export default LegendsList;