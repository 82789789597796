import { Grid, IconButton } from '@mui/material';
import React from 'react';
import { PokerKind, Position, StackSize } from '../../app/api';
import PositionComponent from '../PositionComponent/PositionComponent';
import './PokerKindComponent.css';
import { useAppSelector } from '../../redux/hooks';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface Props {
    pokerKind: PokerKind,
    handlePokerKindContextMenuClick: (event: React.MouseEvent<HTMLElement>, pokerKind: PokerKind) => void,
    handlePositionContextMenuClick: (event: React.MouseEvent<HTMLElement>, position: Position) => void,
    handleStackSizeContextMenuClick: (event: React.MouseEvent<HTMLElement>, stackSize: StackSize) => void, 
}

const PokerKindComponent: React.FC<Props> = ({ pokerKind,
    handlePokerKindContextMenuClick, 
    handlePositionContextMenuClick,
    handleStackSizeContextMenuClick }: Props) => {

    const positions = useAppSelector((state) => state.data.positions);

    return (
        <fieldset key={pokerKind.id} className='border border-solid border-gray-300 p-3'>
            <legend className='text-sm'>
                {pokerKind.title}
                <IconButton onClick={(event) => handlePokerKindContextMenuClick(event, pokerKind)}>
                    <MoreVertIcon />
                </IconButton>
            </legend>
            {
                pokerKind && pokerKind.id && 
                <Grid container>
                {positions[pokerKind.id]?.map((position) => (
                    <Grid item xs={12} md={3} key={position.id}>
                        <PositionComponent position={position}
                            handlePositionContextMenuClick={handlePositionContextMenuClick}
                            handleStackSizeContextMenuClick={handleStackSizeContextMenuClick}
                            />
                    </Grid>
                ))}
            </Grid>
            }
        </fieldset>
    );
}

export default PokerKindComponent;