import { Box, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import React from 'react';
import { SubmitHandler, useForm } from "react-hook-form";
import { object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { UserService } from '../../services/UserService';
import { useAppSelector } from '../../redux/hooks';
import { setIsLoading, setToken } from '../../redux/slices/dataSlice';
import store from '../../redux/store';

const signInSchema = object({
  usernameOrEmail: string()
    .min(1, 'Username or Email is required'),
  password: string()
    .min(1, 'Password is required')
    .min(6, 'Password must be more than 6 characters')
    .max(32, 'Password must be less than 32 characters'),
});

type SignInInput = TypeOf<typeof signInSchema>;

function SignInComponent() {
  const isLoading = useAppSelector((state) => state.data.isLoading);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<SignInInput>({
    resolver: zodResolver(signInSchema)
  });
    
  const onSubmitHandler: SubmitHandler<SignInInput> = (values) => {
    UserService
    .signIn(values.usernameOrEmail, values.password)
      .then((result) => {
        if(result.success && result.token) {
          store.dispatch(setToken(result.token));
        }
        else {
          if(errors.usernameOrEmail && result?.errors && result?.errors['EmailOrUsername']) {
            setError('usernameOrEmail', { type: 'custom', message: result.errors['EmailOrUsername'][0] });
          }
        }
      })
      .catch(() => {
        // @TODO
      })
      .finally(() => {
        store.dispatch(setIsLoading(false));
      });
  };

  return (
    <Box sx={{ maxWidth: '30rem' }}>
      <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
        Sign in
      </Typography>
      <Box
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <TextField
          sx={{ mb: 2 }}
          label='Username or email'
          fullWidth
          required
          error={!!errors['usernameOrEmail']}
          helperText={errors['usernameOrEmail'] ? errors['usernameOrEmail'].message : ''}
          {...register('usernameOrEmail')}
        />
        <TextField
          sx={{ mb: 2 }}
          label='Password'
          fullWidth
          required
          type={showPassword ? 'text' : 'password'}
          error={!!errors['password']}
          helperText={errors['password'] ? errors['password'].message : ''}
          {...register('password')}
          InputProps={{
            endAdornment: <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }}
        />

        <LoadingButton
          variant='contained'
          fullWidth
          type='submit'
          loading={isLoading}
          sx={{ py: '0.8rem', mt: '1rem' }}
        >
          Sign in
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default SignInComponent;