import { Button, Chip, Grid, Slider, TextField } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { Legend, Proportion, StackSize } from '../../app/api';
import { useAppSelector } from '../../redux/hooks';
import HandComponent from '../HandComponent/HandComponent';

interface Props {
    stackSize: StackSize;
    handleSaveStackSize: (stackSize: StackSize) => void; 
    handleCancel: () => void; 
}

class HandEditorRatio{
    legend: Legend;
    ratio: number;
}

const HandsEditorComponent: FC<Props> = ({ stackSize, handleSaveStackSize, handleCancel }: Props) => {
    const handsKeys = ['AA','AKs','AQs','AJs','ATs','A9s','A8s','A7s','A6s','A5s','A4s','A3s','A2s','AKo','KK','KQs','KJs','KTs','K9s','K8s','K7s','K6s','K5s','K4s','K3s','K2s','AQo','KQo','QQ','QJs','QTs','Q9s','Q8s','Q7s','Q6s','Q5s','Q4s','Q3s','Q2s','AJo','KJo','QJo','JJ','JTs','J9s','J8s','J7s','J6s','J5s','J4s','J3s','J2s','ATo','KTo','QTo','JTo','TT','T9s','T8s','T7s','T6s','T5s','T4s','T3s','T2s','A9o','K9o','Q9o','J9o','T9o','99','98s','97s','96s','95s','94s','93s','92s','A8o','K8o','Q8o','J8o','T8o','98o','88','87s','86s','85s','84s','83s','82s','A7o','K7o','Q7o','J7o','T7o','97o','87o','77','76s','75s','74s','73s','72s','A6o','K6o','Q6o','J6o','T6o','96o','86o','76o','66','65s','64s','63s','62s','A5o','K5o','Q5o','J5o','T5o','95o','85o','75o','65o','55','54s','53s','52s','A4o','K4o','Q4o','J4o','T4o','94o','84o','74o','64o','54o','44','43s','42s','A3o','K3o','Q3o','J3o','T3o','93o','83o','73o','63o','53o','43o','33','32s','A2o','K2o','Q2o','J2o','T2o','92o','82o','72o','62o','52o','42o','32o','22'];
    const [handEditorRatios, setHandEditorRatios] = useState<HandEditorRatio[]>([]);
    const [selectedStackSize, setSelectedStackSize] = useState<StackSize>(stackSize);
    const legends = useAppSelector((state) => state.data.legends);

    useEffect(() => {
        setSelectedStackSize(stackSize);
        let handEditorRatios_tmp: HandEditorRatio[] = [];
        for(let i = 0; i < legends.length; i++){
            handEditorRatios_tmp.push({legend: legends[i], ratio:0} as HandEditorRatio);
        }
        setHandEditorRatios(handEditorRatios_tmp);
    }, [stackSize, legends]);

    const onHandClick = (handKey: string) => {
        let stackSizeCopy = JSON.parse(JSON.stringify(selectedStackSize)) as StackSize;
        if(stackSizeCopy && stackSizeCopy.hands){
            stackSizeCopy.hands[handKey] = handEditorRatios.filter(h => h.ratio !== 0).map((h) => ({ rate: h.ratio, legendId: h.legend.id } as Proportion));
        }
        setSelectedStackSize(stackSizeCopy);
    }

    const handleChange = (newValue: number | number[], index: number) => {
        let handEditorRatios_tmp = JSON.parse(JSON.stringify(handEditorRatios)) as HandEditorRatio[];
        handEditorRatios_tmp[index].ratio = newValue as number;
        var total = handEditorRatios_tmp.map(h => h.ratio ?? 0).reduce((a,b) => a+b, 0);
        if(total <= 100){
            setHandEditorRatios(handEditorRatios_tmp);
        }
    }

    return (
        <div>
            <Grid container>
                <Grid item xs={6}>
                    <Button fullWidth variant='contained' color='success' onClick={() => handleSaveStackSize(selectedStackSize)}>Save</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button fullWidth variant='contained' color='error' onClick={() => handleCancel()}>Cancel</Button>
                </Grid>
            </Grid>
            { handEditorRatios.map((h, index) =>
                <Grid container spacing={1}>
                    <Grid item xs={1}>
                        <Chip style={{ backgroundColor: legends.find(l => l.id === h.legend.id)?.color }} />
                    </Grid>
                    <Grid item xs={4}>
                        {h.legend.title}
                    </Grid>
                    <Grid item xs={5}>
                        <Slider value={h.ratio} step={1} min={0} max={100} onChange={(_, value) => handleChange(value, index)} />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField type="number" 
                            InputProps={{
                                inputProps: { min: 0, max: 100 }
                            }}
                            value={h.ratio}
                            fullWidth={true}
                            onChange={(e) => handleChange(+e.target.value, index)} 
                        />
                    </Grid>
                </Grid>
            )}
            <div className='handsContainer'>
                {
                    handsKeys.map((h) => 
                        <HandComponent handleHandClick={() => onHandClick(h)} key={h} autoRandomizer={false} randomizedNumber={0} proportions={selectedStackSize?.hands && selectedStackSize?.hands[h] ? selectedStackSize.hands[h] : []} title={h} />
                    )
                }
            </div>
        </div>

    );
}

export default HandsEditorComponent;