import { Button, Chip, FormControl, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid, GridColDef, ValueOptions } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { Legend, PokerKind, Position, Proportion, StackSize, TrainerTest } from '../../app/api';
import { useAppSelector } from '../../redux/hooks';
import { TrainerTestService } from '../../services/TrainerTestService';

const TrainerComponent = () => {
    const [selectedPokerKindIndex, setSelectedPokerKindIndex] = useState<string>('-1');
    const [selectedPokerKind, setSelectedPokerKind] = useState<PokerKind | undefined>(undefined);
    const [selectedPositionIndex, setSelectedPositionIndex] = useState<string>('-1');
    const [selectedPosition, setSelectedPosition] = useState<Position | undefined>(undefined);
    const [selectedStackSizeIndex, setSelectedStackSizeIndex] = useState<string>('-1');
    const [selectedStackSize, setSelectedStackSize] = useState<StackSize | undefined>(undefined);

    const [randomPokerKind, setRandomPokerKind] = useState<PokerKind | undefined>(undefined);
    const [randomPosition, setRandomPosition] = useState<Position | undefined>(undefined);
    const [randomStackSize, setRandomStackSize] = useState<StackSize | undefined>(undefined);
    const [randomHand, setRandomHand] = useState<string | undefined>(undefined);
    const [randomProportions, setRandomProportions] = useState<Proportion[] | undefined>(undefined);

    const pokerKinds = useAppSelector((state) => state.data.pokerKinds);
    const positions = useAppSelector((state) => state.data.positions);
    const stackSizes = useAppSelector((state) => state.data.stackSizes);
    const legends = useAppSelector((state) => state.data.legends);
    const trainerTests = useAppSelector((state) => state.data.trainerTests);

    const columns: GridColDef[] = [
        { 
            field: 'pokerKindId', headerName: 'Mode', flex: 1, 
            type: 'singleSelect', 
            valueOptions: pokerKinds.map((p) => ({ value: p.id, label: p.title}) as ValueOptions),
            valueFormatter: ({ id: rowId, value, field, api }) => {
                const colDef = api.getColumn(field);
                const option = colDef.valueOptions.find(
                  ({ value: optionValue }) => value === optionValue
                );
              
                return option.label;
              },
        },
        { 
            field: 'positionId', headerName: 'Position', flex: 1,
            type: 'actions',
            renderCell: (params) => (
                <span>
                    {positions[params.row.pokerKindId].find(p => p.id === params.row.positionId)?.title}
                </span>
            )
        },
        { field: 'stackSizeId', headerName: 'Stacksize', flex: 1,
            type: 'actions',
            renderCell: (params) => (
                <span>
                    {stackSizes[params.row.positionId].find(s => s.id === params.row.stackSizeId)?.title}
                </span>
            )
        },
        { field: 'hand', headerName: 'Hand', flex: 1 },
        { field: 'legendId', headerName: 'Answer given', flex: 1,
        type: 'actions',
        renderCell: (params) => (
            <div className='legendTooltipContainer'>
                <div className='legendColorTooltipIndicator' style={{backgroundColor: legends.find(l => l.id === params.row.legendId)?.color}}></div>
                <div className='legendTitle'>{legends.find(l => l.id === params.row.legendId)?.title}</div>
            </div>
        )
        },
        { field: 'actions', headerName: 'Good Answer(s)', flex: 1,
            type: 'actions',
            renderCell: (params) => (
                <div>
                    {
                        stackSizes[params.row.positionId].find(s => s.id === params.row.stackSizeId)?.hands[params.row.hand]?.map(p => 
                            <div className='legendTooltipContainer'>
                                <div className='legendColorTooltipIndicator' style={{backgroundColor: legends.find(l => l.id === p.legendId)?.color}}></div>
                                <div className='legendTitle'>
                                    {legends.find(l => l.id === p.legendId)?.title} <span className='legendRatioWeight'>({p.rate}%)</span>
                                </div>
                            </div>
                        )
                    }
                </div>
            )
        },
    ];

    const handleSelectPokerKind = (e: SelectChangeEvent) => {
        setSelectedPokerKindIndex(e.target.value);
        setSelectedPokerKind(pokerKinds[e.target.value]);
    };

    const handleSelectPosition = (e: SelectChangeEvent) => {
        setSelectedPositionIndex(e.target.value);
        if(selectedPokerKind && selectedPokerKind?.positions) {
            setSelectedPosition(selectedPokerKind.positions[e.target.value]);
        }
    };

    const handleSelectStackSize = (e: SelectChangeEvent) => {
        setSelectedStackSizeIndex(e.target.value);
        if(selectedPosition && selectedPosition?.stackSizes) {
            setSelectedStackSize(selectedPosition.stackSizes[e.target.value]);
        }
    };

    const startTest = () => {
        const randomPokerKindTmp = selectedPokerKind ?? pokerKinds[Math.floor(Math.random() * pokerKinds.length)];
        if(randomPokerKindTmp.positions){
            const randomPositionTmp = selectedPosition ?? randomPokerKindTmp.positions[Math.floor(Math.random() * randomPokerKindTmp.positions.length)];
            if(randomPositionTmp.stackSizes){
                const randomStackSizeTmp = selectedStackSize ?? randomPositionTmp.stackSizes[Math.floor(Math.random() * randomPositionTmp.stackSizes.length)];
                
                if(randomStackSizeTmp.hands) {
                    const keys = Object.keys(randomStackSizeTmp.hands);
                    const randomHand = keys[Math.floor(Math.random() * keys.length)];
                    setRandomPokerKind(randomPokerKindTmp);
                    setRandomPosition(randomPositionTmp);
                    setRandomStackSize(randomStackSizeTmp);
                    setRandomHand(randomHand)
                    setRandomProportions(randomStackSizeTmp.hands[randomHand]);
                }
            }
        }
    };

    const selectAnswer = (selectedLegend: Legend) => {
        const foundProportion = randomProportions?.find((proportion) => proportion.legendId === selectedLegend.id);
        if(foundProportion){
            alert('Yes ! at ' + foundProportion.rate + "% ! Next test");
            startTest();
        }
        else {
            alert('Nope, sorry');
        }
        addAnswer(selectedLegend);
    };

    const addAnswer = (answer : Legend) => {
        let trainerTest = { 
            hand: randomHand,
            legendId: answer.id,
            pokerKindId: randomPokerKind?.id,
            positionId: randomPosition?.id,
            stackSizeId: randomStackSize?.id,
        } as TrainerTest;
        TrainerTestService.handleTrainerTestAdded(trainerTest);
    };

    return (
        <div className='trainerContainer'>
            <fieldset className='border border-solid border-gray-300 p-3'>
                <legend className='text-sm'>Trainer parameters</legend>
                <FormControl fullWidth>
                    <InputLabel>Poker kind</InputLabel>
                    <Select onChange={handleSelectPokerKind} value={selectedPokerKindIndex}>
                        <MenuItem value={-1}>All</MenuItem>
                        { pokerKinds && 
                            pokerKinds.map((pokerKind, index) => (
                                <MenuItem key={index} value={index}>{pokerKind.title}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                {
                    selectedPokerKind && selectedPokerKind.positions &&
                    <FormControl fullWidth>
                        <InputLabel>Position</InputLabel>
                        <Select onChange={handleSelectPosition} value={selectedPositionIndex}>
                                <MenuItem value={-1}>All</MenuItem>
                                {  
                                    selectedPokerKind.positions.map((position, index) => (
                                        <MenuItem key={index} value={index}>{position.title}</MenuItem>
                                    ))
                                }
                            </Select>
                    </FormControl>
                }

                {
                    selectedPosition && selectedPosition.stackSizes &&
                    <FormControl fullWidth>
                        <InputLabel>Stacksize</InputLabel>
                        <Select onChange={handleSelectStackSize} value={selectedStackSizeIndex}>
                            <MenuItem value={-1}>All</MenuItem>
                            {  
                                selectedPosition.stackSizes.map((stacksize, index) => (
                                    <MenuItem key={index} value={index}>{stacksize.title}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                }
                <br/>
                <Button variant='contained' onClick={() => startTest()} className='group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>Start</Button>
            </fieldset>
            {
                randomPokerKind && 
                randomPosition && 
                randomStackSize && 
                randomProportions && 
                <fieldset className='border border-solid border-gray-300 p-3'>
                    <legend className='text-sm'>Test</legend>
                    <div>
                        <Chip label={randomPokerKind?.title} variant="outlined" />
                        <Chip label={randomPosition?.title} variant="outlined" />
                        <Chip label={randomStackSize?.title} variant="outlined" />
                        <Chip label={randomHand} variant="outlined" />
                    </div>
                </fieldset>
            }

            { randomProportions && 
                <fieldset>
                    <legend className='text-sm'>Possible answers</legend>
                    <div>
                        { legends.map((legend, index) => (
                            <Button variant='outlined' onClick={() => selectAnswer(legend)} key={index} style={{ backgroundColor: legend.color }}>{legend.title}</Button>
                        ))}
                    </div>
                </fieldset>
            }

            <fieldset>
            <legend className='text-sm'>History</legend>
                <DataGrid rows={trainerTests} columns={columns} getRowId={(row: any) => row.legendId } autoHeight />
            </fieldset>
        </div>
    );
}

export default TrainerComponent;