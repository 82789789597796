import axios from "axios";
import { CreateStackSizeRequest, StackSize, StackSizeClient, UpdateStackSizeRequest } from "../app/api";
import { stackSizeAdded, stackSizeDeleted, stackSizeUpdated } from "../redux/slices/dataSlice";
import store from "../redux/store";

export abstract class StackSizeService {
    public static handleStackSizeAdded = (stacksize: StackSize): void => {
        let client = new StackSizeClient(process.env.REACT_APP_API_BASE_URL, axios);
        let request = new CreateStackSizeRequest();
        request.init(stacksize);
        client.create(request).then((item) => {
            if(item){
                store.dispatch(stackSizeAdded(new StackSize(item)))
            }
        });
    };

    public static handleStackSizeUpdated(stacksize: StackSize): void {
        let client = new StackSizeClient(process.env.REACT_APP_API_BASE_URL, axios);
        let request = new UpdateStackSizeRequest();
        request.init(stacksize);
        if(stacksize.id){
            client.update(stacksize.id, request).then((item) => {
                store.dispatch(stackSizeUpdated(new StackSize(item)))
            });
        }
    }
    
    public static handleStackSizeDeleted(stacksize: StackSize): void {
        let client = new StackSizeClient(process.env.REACT_APP_API_BASE_URL, axios);
        if(stacksize.id){
            client.delete(stacksize.id).then(() => {
                store.dispatch(stackSizeDeleted(stacksize))
            });
        }
    }
};