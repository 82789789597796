import './HandComponent.css';
import React, { FC, useEffect, useState } from 'react';
import { Proportion } from '../../app/api';
import { Fade, Tooltip } from '@mui/material';
import HandTooltipComponent from '../HandTooltipComponent/HandTooltipComponent';
import { useAppSelector } from '../../redux/hooks';

interface Props {
    title: string;
    proportions: Proportion[];
    handleHandClick: (title: string, proportions: Proportion[]) => void;
}

const HandComponent: FC<Props> = ( {proportions, title, handleHandClick}: Props) => {
    const [randomizedProportion, setRandomizedProportion] = useState<Proportion>();
    const legends = useAppSelector((state) => state.data.legends);
    const randomizedNumber = useAppSelector((state) => state.data.randomizedNumber);
    const autoRandomizer = useAppSelector((state) => state.data.autoRandomizer);

    useEffect(() => {
        if(autoRandomizer === true && proportions){
            if(proportions.length === 1) {
                setRandomizedProportion(proportions[0])
            }
            else if(proportions.length > 1) {
                setRandomizedProportion(getRandomizedProportion(proportions, randomizedNumber));
            }
            else {
                setRandomizedProportion(undefined);
            }
        }
    }, [proportions, autoRandomizer, randomizedNumber]);

    const getRandomizedProportion = (proportions: Proportion[], randomizedNumber: Number) => {
        let count = 0; 
        let proportionFound: Proportion | undefined = undefined;
        for(var i = 0; i < proportions.length; i++){
            let currentRate = proportions?.at(i)?.rate;
            if(currentRate){
                if(currentRate && randomizedNumber >= count && randomizedNumber <= (count + currentRate)) {
                    proportionFound = proportions[i];
                    break;
                }
                count += currentRate;
            }
        }
        return proportionFound;
    };

    return (
        <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 200 }}
            title={
                proportions && proportions?.length > 0 &&
                <HandTooltipComponent proportions={proportions} />
            }
            arrow>
            <div className='handContainer' color="primary" onClick={() => handleHandClick(title, proportions)}>
                <div className='handTitle'>{title}</div>
                { autoRandomizer && randomizedProportion
                    ?
                        <div className='proportionBackground' style={{backgroundColor: legends.filter(l => l.id === randomizedProportion.legendId)[0].color, width: '100%'}}>&nbsp;</div>
                    :
                    <div className='proportionsContainer'>
                        { proportions && proportions.map((proportion, index) => (
                            legends.filter(l => l.id === proportion.legendId).length > 0 && <p className='proportionBackground' key={title + " " + index} style={{backgroundColor: legends.filter(l => l.id === proportion.legendId)[0].color,
                                                                width: proportion.rate + "%"}}>&nbsp;</p>
                        )) }
                        { proportions && proportions.length === 0 ? <p className='proportionBackground' style={{backgroundColor: 'transparent', width: '100%'}}>&nbsp;</p> : '' }
                    </div>
                }
            </div>
        </Tooltip>
    );
}

export default HandComponent;