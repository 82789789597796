import { createSlice } from "@reduxjs/toolkit";
import { Legend, PokerKind, Position, StackSize, TrainerTest } from "../../app/api";

const initialState = {
    token: window.localStorage.getItem('accessToken') as string | null,
    isLoading: false as boolean,
    legends: [] as Legend[],
    pokerKinds: [] as PokerKind[],
    positions: {} as { [key: string] : Position[]; },
    stackSizes: {} as { [key: string] : StackSize[]; },
    trainerTests: [] as TrainerTest[],
    selectedPokerKind: undefined as unknown as PokerKind,
    selectedPosition: undefined as unknown as Position, 
    selectedStackSize: undefined as unknown as StackSize,
    autoRandomizer: false as boolean,
    randomizedNumber: 0 as Number,
};

export const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setSelectedPokerKind(state, action) {
            state.selectedPokerKind = action.payload;
        },
        setSelectedPosition(state, action) {
            state.selectedPosition = action.payload;
        },
        setSelectedStackSize(state, action) {
            state.selectedStackSize = action.payload;
            let max = 100; 
            let min = 0; 
            state.randomizedNumber = Math.floor(Math.random() * (max - min + 1)) + min;
        },
        setTrainerTests(state, action) {
            state.trainerTests = action.payload;
        },
        setLegends(state, action) {
            state.legends = action.payload;
        },
        setPokerKinds(state, action) {
            state.pokerKinds = action.payload;
            state.pokerKinds.forEach(pokerKind => {
                if(pokerKind.id){
                    state.positions[pokerKind.id] = pokerKind.positions as Position[];
                    state.positions[pokerKind.id].forEach(position => {
                        if(position.id) {
                            state.stackSizes[position.id] = position.stackSizes as StackSize[];
                        }
                    });
                }
            });
        },
        setAutoRandomizer(state, action) {
            state.autoRandomizer = action.payload;
        },
        setToken(state, action) {
            window.localStorage.setItem('accessToken', action.payload);
            state.token = action.payload;
        },
        updateRandomizedNumber(state, _) {
            let max = 100; 
            let min = 0; 
            state.randomizedNumber = Math.floor(Math.random() * (max - min + 1)) + min;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },

        legendAdded(state, action) {
            state.legends.push(action.payload as Legend);
        },
        legendUpdated(state, action) {
            state.legends.find((legend) => legend.id === action.payload.id)?.init(action.payload);
        },
        legendDeleted(state, action) {
            state.legends = state.legends.filter((legend) => legend.id !== action.payload.id);
        },

        pokerKindAdded(state, action) {
            state.pokerKinds.push(action.payload as PokerKind);
        },
        pokerKindUpdated(state, action) {
            let pokerKindUpdated = state.pokerKinds.find((p) => p.id === action.payload.id);
            pokerKindUpdated?.init(action.payload);
            state.pokerKinds = state.pokerKinds.map(p => p.id === pokerKindUpdated?.id ? pokerKindUpdated : p) as PokerKind[];
        },
        pokerKindDeleted(state, action) {
            state.pokerKinds = state.pokerKinds.filter((pokerKinds) => pokerKinds.id !== action.payload.id);
        },

        positionAdded(state, action) {
            state.positions[action.payload.pokerKindId] = [...state.positions[action.payload.pokerKindId], action.payload];
        },
        positionUpdated(state, action) {
            let positionUpdated = state.positions[action.payload.pokerKindId].find(p => p.id === action.payload.id);
            positionUpdated?.init(action.payload); 
            state.positions[action.payload.pokerKindId] = state.positions[action.payload.pokerKindId].map(p => p.id === action.payload.id ? positionUpdated : p) as Position[];
        },
        positionDeleted(state, action) {
            state.positions[action.payload.pokerKindId] = state.positions[action.payload.pokerKindId].filter(p => p.id !== action.payload.id) as PokerKind[];
        },

        stackSizeAdded(state, action) {
            state.stackSizes[action.payload.positionId] = [...state.stackSizes[action.payload.positionId], action.payload];
        },
        stackSizeUpdated(state, action) {
            let stackSizeUpdated = state.stackSizes[action.payload.positionId].find(p => p.id === action.payload.id);
            stackSizeUpdated?.init(action.payload); 
            state.stackSizes[action.payload.positionId] = state.stackSizes[action.payload.positionId].map(s => s.id === action.payload.id ? stackSizeUpdated : s) as StackSize[];
        },
        stackSizeDeleted(state, action) {
            state.stackSizes[action.payload.positionId] = state.stackSizes[action.payload.positionId].filter(p => p.id !== action.payload.id) as StackSize[];
        },

        trainerTestAdded(state, action) {
            state.trainerTests = [...state.trainerTests, action.payload];
        },
    },
});

export const { 
    setSelectedPokerKind, setSelectedPosition, setSelectedStackSize, 
    setPokerKinds, setLegends, setTrainerTests,
    setAutoRandomizer, setToken, setIsLoading,
    legendAdded, legendUpdated, legendDeleted, 
    pokerKindAdded, pokerKindUpdated, pokerKindDeleted,
    positionAdded, positionUpdated, positionDeleted,
    stackSizeAdded, stackSizeUpdated, stackSizeDeleted,
    trainerTestAdded
} = dataSlice.actions;

export default dataSlice.reducer;