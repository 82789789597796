import './HandTooltipComponent.css';
import React from 'react';
import { Proportion } from '../../app/api';
import { useAppSelector } from '../../redux/hooks';

function HandTooltipComponent(props) {
    const proportions : Proportion[] = props.proportions;
    const legends = useAppSelector((state) => state.data.legends);

    return (
        <div>
            { proportions.map((proportion, index) => (
                <div key={index} className='legendTooltipContainer'>
                    <div className='legendColorTooltipIndicator' style={{backgroundColor: legends.filter(l => l.id === proportion.legendId)[0].color}}></div>
                    <div className='legendTitle'>{proportion.rate}% - {legends.filter(l => l.id === proportion.legendId)[0].title}</div>
                </div>
            )) }
        </div>
    );
}

export default HandTooltipComponent;