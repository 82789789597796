import './DashboardComponent.css';
import React, { useState } from 'react';
import { Legend, PokerKind, Position, Proportion, StackSize } from '../../app/api';
import PokerKindComponent from '../PokerKindComponent/PokerKindComponent';
import HandsComponent from '../HandsComponent/HandsComponent';
import HandsLegendsComponent from '../HandsLegendsComponent/HandsLegendsComponent';
import { alpha, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Menu, MenuItem, MenuProps, styled, Switch } from '@mui/material';
import PokerKindFormDialog from '../FormDialogs/PokerKindFormDialog';
import PositionFormDialog from '../FormDialogs/PositionFormDialog';
import StackSizeFormDialog from '../FormDialogs/StackSizeFormDialog';
import HandProportionsFormDialog from '../FormDialogs/HandProportionsFormDialog';
import HandsEditorComponent from '../HandsEditorComponent/HandsEditorComponent';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { PokerKindService } from '../../services/PokerKindService';
import { PositionService } from '../../services/PositionService';
import { useAppSelector } from '../../redux/hooks';
import { setAutoRandomizer, setSelectedPokerKind, setSelectedPosition, setSelectedStackSize } from '../../redux/slices/dataSlice';
import store from '../../redux/store';
import { StackSizeService } from '../../services/StackSizeService';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    border: '1px solid white',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const DashboardComponent = () => {
  const selectedPokerKind = useAppSelector((state) => state.data.selectedPokerKind);
  const selectedPosition = useAppSelector((state) => state.data.selectedPosition);
  const selectedStackSize = useAppSelector((state) => state.data.selectedStackSize);
  const randomizedNumber = useAppSelector((state) => state.data.randomizedNumber);
  const autoRandomizer = useAppSelector((state) => state.data.autoRandomizer);
  const pokerKinds = useAppSelector((state) => state.data.pokerKinds);
  
  const [editPokerKindAlertOpen, setEditPokerKindAlertOpen] = useState(false);
  const [editPositionAlertOpen, setEditPositionAlertOpen] = useState(false);
  const [editStackSizeAlertOpen, setEditStackSizeAlertOpen] = useState(false);
  const [editHandProportionsAlertOpen, setEditHandProportionsAlertOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [editStackSizeMode, setEditStackSizeMode] = useState(false);
  const [selectedHandTitle, setSelectedHandTitle] = useState<string>();

  const [selectedProportions, setSelectedProportions] = useState<Proportion[]>([]);

  const handleDeletePokerKindClick = () => {
    setDeleteAlertOpen(true);
  }

  const handleHandClick = (title: string, proportions: Proportion[]) => {
    setSelectedHandTitle(title);
    setSelectedProportions(proportions ?? []);
    setEditHandProportionsAlertOpen(true);
  }

  const handleDeletePositionClick = () => {
    PositionService.handlePositionDeleted(selectedPosition);
  }

  const handleEditStackSizeClick = () => {
    setDialogTitle('Edit stack size')
    setEditStackSizeAlertOpen(true);
  }

  const handleDeleteStackSizeClick = () => {
    StackSizeService.handleStackSizeDeleted(selectedStackSize);
  }

  const handleAddStackSizeClick = () => {
    store.dispatch(setSelectedStackSize({ id: undefined, title: '', positionId: selectedPosition.id } as StackSize));
    setDialogTitle('Add stack size')
    setEditStackSizeAlertOpen(true);
  }

  const handleAddPokerKindClick = () => {
    store.dispatch(setSelectedPokerKind({ id: undefined, title: '' } as PokerKind));
    setDialogTitle('Add poker kind')
    setEditPokerKindAlertOpen(true);
  }

  const handleEditPokerKindClick = () => {
    setDialogTitle('Edit poker kind')
    setEditPokerKindAlertOpen(true);
  }

  const handleAddPositionClick = () => {
    store.dispatch(setSelectedPosition({ id: undefined, title: '', pokerKindId: selectedPokerKind.id, stackSizes: [] } as unknown as Position));
    setDialogTitle('Add position')
    setEditPositionAlertOpen(true);
  }

  const handleEditPositionClick = () => {
    setDialogTitle('Edit position')
    setEditPositionAlertOpen(true);
  }

  const handleClosePokerKind = () => {
    setEditPokerKindAlertOpen(false);
  };

  const handleClosePosition = () => {
    setEditPositionAlertOpen(false);
  };

  const handleCloseStackSize = () => {
    setEditStackSizeAlertOpen(false);
  };

  const handleSaveHandProportions = (newProportions: Proportion[]) => {
    if(selectedHandTitle && 
      selectedStackSize &&
      selectedStackSize.id && 
      selectedStackSize.hands) {
        selectedStackSize.hands[selectedHandTitle] = newProportions;
        StackSizeService.handleStackSizeUpdated(selectedStackSize);
        setSelectedStackSize(selectedStackSize);
    }
    setEditHandProportionsAlertOpen(false);
  }

  const deletePokerKind = () => {
    setDeleteAlertOpen(false);
    if(selectedPokerKind)
      PokerKindService.handlePokerKindDeleted(selectedPokerKind);
  }

  const handleSavePokerKind = (item: PokerKind) => {
    setEditPokerKindAlertOpen(false);
    if (item.id) {
      PokerKindService.handlePokerKindUpdated(item);
    } else {
      PokerKindService.handlePokerKindAdded(item);
    }
  }

  const handleSavePosition = (item: Position) => {
    setEditPositionAlertOpen(false);
    if (item.id) {
      PositionService.handlePositionUpdated(item);
    } else {
      PositionService.handlePositionAdded(item);
    }
  }

  const handleCloseHandProportions = () => {
    setEditHandProportionsAlertOpen(false);
  };

  const handleSaveStackSize = (item: StackSize) => {
    setEditStackSizeAlertOpen(false);
    setEditStackSizeMode(false);
    if (item.id) {
      StackSizeService.handleStackSizeUpdated(item);
    } else {
      StackSizeService.handleStackSizeAdded(item);
    }
  }
    
    function handleBulkUpdateStackSizeLegend(rangeText: string, weight: number, legend: Legend): void {
      if(selectedStackSize && selectedStackSize.id){
        var hands = getHandsSplitted(rangeText);
        for(var i=0;i<hands.length;i++){
          if(selectedStackSize.hands){
            // peut être juste ajout plutôt que vraiment réinitialiser tout ? 
            selectedStackSize.hands[hands[i]] = [{ 
              legendId: legend.id, 
              rate: weight, 
            } as Proportion];
          }
        }
        StackSizeService.handleStackSizeUpdated(selectedStackSize);
      }
    }

    const getHandsSplitted = (inputHands:string): string[] => {
      var handsSplitted = inputHands.split(',');
      var allHands:string[] = [];
      for(var i = 0; i < handsSplitted.length; i++){
        allHands = allHands.concat(getAllHands(handsSplitted[i]));
      }
      var uniqueHands = uniqueArray(allHands);
      return uniqueHands; 
    };

    const uniqueArray = (array:string[]) => {
      return [...new Set(array)];
    }

    const getAllHands = (singleHand:string) :string[] => {
      // @TODO : manque le tiret ! 
      if(!singleHand.endsWith('+')){
        return [singleHand];
      }
      else{
        return [...getAllHands(getNextHand(singleHand)), singleHand.substring(0,singleHand.length-1)];
      }
    }

    const getNextHand = (hand:string) :string => {
      switch (hand) {
        case '22+': return '33+';
        case '33+': return '44+';
        case '44+': return '55+';
        case '55+': return '66+';
        case '66+': return '77+';
        case '77+': return '88+';
        case '88+': return '99+';
        case '99+': return 'TT+';
        case 'TT+': return 'JJ+';
        case 'JJ+': return 'QQ+';
        case 'QQ+': return 'KK+';
        case 'KK+': return 'AA';
  
        case '32s+': return '43s+';
        case '43s+': return '54s+';
        case '54s+': return '65s+';
        case '65s+': return '76s+';
        case '76s+': return '87s+';
        case '87s+': return '98s+';
        case '98s+': return 'T9s+';
        case 'T9s+': return 'JTs+';
        case 'JTs+': return 'QJs+';
        case 'QJs+': return 'KQs+';
        case 'KQs+': return 'AKs';
  
        case '32o+': return '43o+';
        case '43o+': return '54o+';
        case '54o+': return '65o+';
        case '65o+': return '76o+';
        case '76o+': return '87o+';
        case '87o+': return '98o+';
        case '98o+': return 'T9o+';
        case 'T9o+': return 'JTo+';
        case 'JTo+': return 'QJo+';
        case 'QJo+': return 'KQo+';
        case 'KQo+': return 'AKo';
  
        case 'A2o+': return 'A3o+';
        case 'A3o+': return 'A4o+';
        case 'A4o+': return 'A5o+';
        case 'A5o+': return 'A6o+';
        case 'A6o+': return 'A7o+';
        case 'A7o+': return 'A8o+';
        case 'A8o+': return 'A9o+';
        case 'A9o+': return 'ATo+';
        case 'ATo+': return 'AJo+';
        case 'AJo+': return 'AQo+';
        case 'AQo+': return 'AKo+';
        case 'AKo+': return 'AA';
  
        case 'A2s+': return 'A3s+';
        case 'A3s+': return 'A4s+';
        case 'A4s+': return 'A5s+';
        case 'A5s+': return 'A6s+';
        case 'A6s+': return 'A7s+';
        case 'A7s+': return 'A8s+';
        case 'A8s+': return 'A9s+';
        case 'A9s+': return 'ATs+';
        case 'ATs+': return 'AJs+';
        case 'AJs+': return 'AQs+';
        case 'AQs+': return 'AKs+';
        case 'AKs+': return 'AA';
  
        default: return 'AA';
      }
    }

    const [anchorElPokerKind, setAnchorElPokerKind] = React.useState<null | HTMLElement>(null);
    const openPokerKindContextMenu = Boolean(anchorElPokerKind);
    const handlePokerKindClick = (event: React.MouseEvent<HTMLElement>, pokerKind: PokerKind) => {
      store.dispatch(setSelectedPokerKind(pokerKind));
      setAnchorElPokerKind(event.currentTarget);
    };
    const [anchorElPosition, setAnchorElPosition] = React.useState<null | HTMLElement>(null);
    const openPositionContextMenu = Boolean(anchorElPosition);
    const handlePositionClick = (event: React.MouseEvent<HTMLElement>, position: Position) => {
      store.dispatch(setSelectedPosition(position));
      setAnchorElPosition(event.currentTarget);
    };
    const [anchorElStackSize, setAnchorElStackSize] = React.useState<null | HTMLElement>(null);
    const openStackSizeContextMenu = Boolean(anchorElStackSize);
    const handleStackSizeClick = (event: React.MouseEvent<HTMLElement>, stackSize: StackSize) => {
      store.dispatch(setSelectedStackSize(stackSize));
      setAnchorElStackSize(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorElPokerKind(null);
      setAnchorElPosition(null);
      setAnchorElStackSize(null);
    };

    return (
      <Grid container>
        <Grid item xs={12} md={8} order={{ xs: 2, md:1 }}>
          <Grid container>
            {pokerKinds?.map((pokerKind) => (
              <Grid item xs={12} key={pokerKind.id}>
                <PokerKindComponent 
                  handlePokerKindContextMenuClick={handlePokerKindClick}
                  handlePositionContextMenuClick={handlePositionClick}
                  handleStackSizeContextMenuClick={handleStackSizeClick}
                  pokerKind={pokerKind} 
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button variant='outlined' color='success' onClick={handleAddPokerKindClick}>Add poker mode</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} order={{ xs: 1, md:2 }}>
          {selectedStackSize &&
            <fieldset className='selectedStackSizeContainer border border-solid border-gray-300 p-3'>
                <legend className='text-sm'>
                    {selectedPosition.title} - {selectedStackSize.title}
                    {
                      !editStackSizeMode && 
                      <span>
                        <Switch checked={autoRandomizer} onChange={(element) => setAutoRandomizer(element.currentTarget.checked)} />
                        {autoRandomizer && <span>{randomizedNumber.toString()}</span>}
                        <IconButton color='info' aria-label="Edit" onClick={() => setEditStackSizeMode(!editStackSizeMode)}>
                            <EditRoundedIcon />
                        </IconButton>
                      </span>
                    }
                </legend>
                {
                  editStackSizeMode ? 
                    <HandsEditorComponent handleSaveStackSize={handleSaveStackSize} handleCancel={() => setEditStackSizeMode(false)} stackSize={selectedStackSize} />
                  :
                    <HandsComponent handleHandClick={handleHandClick} stackSize={selectedStackSize} />
                }
                <HandsLegendsComponent stackSize={selectedStackSize} handleBulkUpdateStackSizeLegend={handleBulkUpdateStackSizeLegend} />
            </fieldset>
          }
        </Grid>
        <Dialog
          open={deleteAlertOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
          {"Delete poker kind"}
          </DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
              Are you sure ?
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={() => {setDeleteAlertOpen(false)}} variant='outlined'>Cancel</Button>
          <Button onClick={deletePokerKind} variant='contained' color='error' autoFocus>
              Delete
          </Button>
          </DialogActions>
        </Dialog>
      {
        selectedPokerKind && 
        <PokerKindFormDialog item={selectedPokerKind} open={editPokerKindAlertOpen} title={dialogTitle} onSave={handleSavePokerKind} onCancel={handleClosePokerKind} />
      }
      {
        selectedPosition && 
        <PositionFormDialog item={selectedPosition} open={editPositionAlertOpen} title={dialogTitle} onSave={handleSavePosition} onCancel={handleClosePosition} />
      }
      {
        selectedStackSize && 
        <StackSizeFormDialog item={selectedStackSize} open={editStackSizeAlertOpen} title={dialogTitle} onSave={handleSaveStackSize} onCancel={handleCloseStackSize} />
      }

      <StyledMenu open={openPokerKindContextMenu} onClose={handleClose} anchorEl={anchorElPokerKind}>
        <MenuItem onClick={() => handleEditPokerKindClick()}>
          <EditIcon />
          Edit
        </MenuItem>
        <MenuItem onClick={() => handleAddPositionClick()}>
          <AddIcon color='success' />
          Add Position
        </MenuItem>
        <MenuItem onClick={() => handleDeletePokerKindClick()}>
          <DeleteIcon color='error' />
          Delete
        </MenuItem>
      </StyledMenu>

      <StyledMenu open={openPositionContextMenu} onClose={handleClose} anchorEl={anchorElPosition}>
        <MenuItem onClick={() => handleEditPositionClick()}>
          <EditIcon />
          Edit
        </MenuItem>
        <MenuItem onClick={() => handleAddStackSizeClick()}>
          <AddIcon color='success' />
          Add StackSize
        </MenuItem>
        <MenuItem onClick={() => handleDeletePositionClick()}>
          <DeleteIcon color='error' />
          Delete
        </MenuItem>
      </StyledMenu>

      <StyledMenu open={openStackSizeContextMenu} onClose={handleClose} anchorEl={anchorElStackSize}>
        <MenuItem onClick={() => handleEditStackSizeClick()}>
          <EditIcon />
          Edit
        </MenuItem>
        <MenuItem onClick={() => handleDeleteStackSizeClick()}>
          <DeleteIcon color='error' />
          Delete
        </MenuItem>
      </StyledMenu>

      <HandProportionsFormDialog proportions={selectedProportions} open={editHandProportionsAlertOpen} onCancel={handleCloseHandProportions} onSave={handleSaveHandProportions} />
      </Grid>
    );
}

export default DashboardComponent;
